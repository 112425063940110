import storyData from './story.js';
import './AppStyles.css'; // At the top of your App.js file
import React, { useState, useEffect } from 'react';
import { Container, Typography, IconButton, Grid, Paper, ButtonGroup, Button } from '@mui/material';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'; // Assuming this is close to what you want
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BookIcon from '@mui/icons-material/Book';
import ChatIcon from '@mui/icons-material/Chat';
import CreateIcon from '@mui/icons-material/Create';

function App() {
  const [length, setLength] = useState('medium');
  const [style, setStyle] = useState('poetic');
  const [section, setSection] = useState(0);
  const [wordCount, setWordCount] = useState(0);

  const currentStory = storyData[length][style][section];
  const displayText = currentStory.text;
  const displayTitle = currentStory.title;

  const configurationExperience = {
    length: {
      short: { descriptor: "quick", journeyType: "sprint" },
      medium: { descriptor: "thoughtful", journeyType: "expedition" },
      long: { descriptor: "immersive", journeyType: "odyssey" }
    },
    style: {
      formal: { descriptor: "analytical", journeyType: "investigation" },
      informal: { descriptor: "casual", journeyType: "adventure" },
      poetic: { descriptor: "artistic", journeyType: "voyage" }
    }
  };
  

  useEffect(() => {
    const countWords = text => text.split(/\s+/).filter(Boolean).length;
    setWordCount(countWords(displayText));
  }, [displayText, section, length, style]);

  const getIconButtonStyle = (targetLength) => ({
    color: length === targetLength ? '#1976d2' : 'default', // Blue color for active length
    border: length === targetLength ? '1px solid #1976d2' : 'none', // Border for active button
  });

  const getButtonStyle = (targetStyle) => ({
    boxShadow: style === targetStyle ? '0 2px 4px rgba(0, 0, 0, 0.2)' : 'none', // Shadow effect for active style
  });

  const handleLengthChange = (newLength) => {
    setLength(newLength);
    setSection(0);
  };

  const handleStyleChange = (newStyle) => {
    setStyle(newStyle);
    setSection(0);
  };


  return (
    <div style={{ background: "url('path/to/your/indian-pattern-background.svg')", minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px 0' }}>
      <Container maxWidth="sm" style={{ marginBottom: '20px' }} className="control-panel">
        <Paper elevation={3} style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#fff' }}>
          {/* Length and Style Selection Icons */}
          {/* Length Selection Icons */}
          <Grid container justifyContent="center" spacing={2}>
            <Grid item>
              <IconButton onClick={() => handleLengthChange('short')} style={getIconButtonStyle('short')}>
                <DoubleArrowIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton onClick={() => handleLengthChange('medium')} style={getIconButtonStyle('medium')}>
                <DoubleArrowIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton onClick={() => handleLengthChange('long')} style={getIconButtonStyle('long')}>
                <ArrowForwardIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Typography variant="body1" style={{ marginTop: '10px', marginBottom: '20px', textAlign: 'center' }} className="description-text">
            Dive into a {configurationExperience.length[length].descriptor} and {configurationExperience.style[style].descriptor} {configurationExperience.length[length].journeyType}.
          </Typography>


          {/* Style Selection Icons */}
          <Grid container justifyContent="center" spacing={2} style={{ marginTop: '20px' }}>
            <Grid item>
              <IconButton onClick={() => handleStyleChange('formal')} style={getButtonStyle('formal')}>
                <BookIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton onClick={() => handleStyleChange('informal')} style={getButtonStyle('informal')}>
                <ChatIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton onClick={() => handleStyleChange('poetic')} style={getButtonStyle('poetic')}>
                <CreateIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      
      {/* Story content section with justified text */}
      <Container maxWidth="md" style={{ width: '70%' }} className="story-container">
        <Paper elevation={3} style={{ padding: '20px', backgroundColor: '#fafafa' }}>
          <Typography variant="caption" style={{ position: 'relative', top: 10, left: 20 }}>
            Word Count: {wordCount}
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
            <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
              {storyData[length][style].map((_, index) => (
                <Button key={index} onClick={() => setSection(index)} 
                style={{ 
                  boxShadow: section === index ? '0 2px 4px rgba(0, 0, 0, 0.3)' : 'none',
                  backgroundColor: section === index ? '#1976d2' : '', // Make background color blue for active button
                  color: section === index ? '#fff' : '', // Change text color to white for contrast
                }}
                >
                  {index + 1}
                </Button>
              ))}
            </ButtonGroup>
          </div>
          <Typography variant="h6" style={{ textAlign: 'center', marginBottom: '20px' }}>
            {displayTitle}
          </Typography>
          <Typography variant="body1" style={{ textAlign: 'justify', whiteSpace: 'pre-line' }}>
            {displayText}
          </Typography>
        </Paper>
      </Container>
    </div>
  );
}

export default App;
