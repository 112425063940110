const storyData = {
  short: {
    formal: [
      {
        title:"",
        text:""
      },
      {
        title:"",
        text:""
      },
      {
        title:"",
        text:""
      }
    ],
    informal: [
      {
        title:"",
        text:""
      },
      {
        title:"",
        text:""
      },
      {
        title:"",
        text:""
      }
    ],
    poetic: [
      {
        title:"",
        text:""
      },
      {
        title:"",
        text:""
      },
      {
        title:"",
        text:""
      }
    ],
  },
  medium: {
    formal: 
    [
      { 
        //riddles of the crimson flow 1st person (1)
        title: "A Day of Inquiry",
        text:`
        The steering wheel feels like a shackle, cold and unyielding in my grip. It anchors me to a life that cycles endlessly through traffic signals and the blare of horns. Inside my tempo's cabin, the world contracts to the size of a matchbox, stuffed corners with sacks of grain and the lingering scent of cattle fodder. It's a mobile prison, and I'm both the prisoner and the jailer.

        
  As I navigate the tempo through Pune's arteries, the radio crackles to life, narrating tales of distant lands and love, as remote to me as the stars. Each vibration of the dashboard under the bass notes composes a melody of escape, a fantasy I can only indulge in. The cabin, with its peeling paint and a faded talisman dancing from the rearview mirror, encapsulates my isolation. Here, I'm severed from the world, yet paradoxically at its core, maneuvering through its disorder.

  Every red light serves as a pause, a momentary break in my day's sentence, allowing me to dream beyond the glass. But as Shivaji Market nears, those dreams evaporate. The market emerges, a tapestry of hues and sounds, drawing me back from the brink of my reveries. I ease the tempo into the butcher's square, its aged engine's rattle heralding my arrival even before I disembark. Here, the radio's tune succumbs to the market's rhythm, reminding me that within my tempo's confines lies my refuge from the external tumult.

  The young sons of Farooq-chacha, their lean figures all smiles and agility, dart from the building's shade to greet me, their zeal contrasting sharply with the weight of fatigue on my shoulders. They swarm the vehicle's rear, their youthful vigor making light of the laborious task, their chatter a melody that no longer reaches me.

  The cattle, aware their journey's end is near, stir and vocalize, their sounds blending with the market's cadence. I stand watch, a silent guardian, as the animals disembark. The usual steadiness of the older brother's absence leaves a gap, making me feel unbalanced, like a tooth gone missing.

  The sun, relentless in its vigil, bathes me in its gaze, tracing the line of sweat down my back, a path of least resistance. On days like these, when the cabin becomes an oven, I yearn for a reprieve—something chilled, a hint of sweetness. This craving solidifies with each escaping droplet. My eyes catch the worn sign of a general store by the vegetable market's edge, a seldom-visited place now summoning me with the promise of cool shade.

  I thread my way through Shivaji Market's vibrant disorder, choosing to traverse its heart instead of taking the direct route through Farooq’s butchery. The market pulses with life, each vendor's call and bargain a heartbeat through the narrow pathways lined with a riot of produce and wares.

  Drawing near the general store outside the vegetable market, my attention is captured by an older man approaching the store. Something about his unhurried pace compels me to wait, observing silently. His steps are measured, as if commanding time to adapt to his rhythm.

  Near the store, a small canal runs, its presence stark against the nearby vibrant vegetable hues. It's a vein of shadow, ferrying away the dark remnants of Farooq's operations. Raised on a farm, I'm no stranger to the lifecycle of livestock, yet the canal's proximity to the fresh produce strikes me as peculiar.

  The store's owner, noticing me by the door, greets me with a smile seasoned with years of customer reassurances. "Just animal blood," he remarks, as if reading my thoughts, unaware of who I am. His continued smile and the crinkling of his eyes attempt to offer comfort, but it only accentuates the canal's odd location.

  I nod, the mingled scents of iron and soil a vivid reminder of the market's essence—a place where existence and livelihood intertwine intimately. The juxtaposition of life's vibrancy against its inevitable cessation is starkly evident in the canal's flow, igniting a curiosity within me that I hadn't expected. Today feels different; the market, as familiar as my own hand, now harbors a secret awaiting discovery.

  I remain by the store, observing the canal while the remnants of my ice cream melt unnoticed. The proprietor departs again towards the vegetable market's bustle, engaging in conversation with a stall-owning woman as I contemplate the canal. The sight of a lone bone resting near the canal, so close to the vegetables, deepens my confusion.

  Outside the butchery, the butchers' idle banter fills the air during their smoke break. They're a diverse group, each reflecting the meats they handle. Aslam's laughter echoes, Irfan offers quiet, steady smiles, and Junaid's hands flutter with rapid gestures. I approach, masking my deep curiosity with casual interest, and inquire about the fate of the bones.

  Aslam, taking a drag from his cigarette, exhales a cloud of smoke before answering. "Oh, they're all accounted for," he says with a grin. "Nothing goes to waste here. The larger ones, well, they can be boiled down for stock, or some folks like them for their dogs."

  Irfan chimes in, his voice soft but firm, "And the smaller bits, we usually leave out for the strays. Can't have them going hungry, can we?" His smile reaches his eyes, a glint of kindness there that belies the rough exterior.

  Junaid, leaning against the wall, adds, "Yeah, nothing's left to drain. We make sure of that. It’s all part of the process, keeps everything in balance."

  As the butchers extinguish their cigarettes and resume work, my intrigue blossoms into a full investigation, determined to unravel the market's secrets.
    
        `
      }
      ,
      {
        title:"",
        text:""
      },
      {
        title:"",
        text:""
      }
    ],
    informal: 
    [
      {
        //riddles of the crimson flow 1st person (2)
        title: "A Puzzling Flow",
        text:`
        The steering wheel feels like a shackle, cold and unyielding under my hands. It's like an anchor, tying me down to a never-ending cycle of traffic lights and endless honking. Inside my tempo, the world narrows down to just this tiny space, crammed with grain sacks and smelling faintly of cattle. It's kind of like being in a moving cell where I'm both the prisoner and the guard.

  Driving through Pune, the radio brings stories of places and loves that seem as far out of reach as the stars. The dashboard shakes with each beat, playing out tunes of escape that I can only dream about. The cabin, with its chipped paint and the old charm swinging from the mirror, feels like a bubble of isolation. Here, I'm cut off, yet weirdly right in the middle of everything, navigating the chaos.

  Each red light is like a timeout, a brief halt in the monotony, tempting me to dream a little. But as I get closer to Shivaji Market, those dreams start to fade away. The market comes into view, bursting with colors and noise, pulling me back to reality. I find a spot to park my tempo, the music from the radio now lost in the market's own beats, a stark reminder that this tempo, despite its confines, is my little sanctuary.

  The sons of Farooq-chacha, these skinny, fast-moving kids, rush out to meet me, their energy a stark contrast to the tiredness weighing me down. They quickly get busy with the tempo, their chatter a background noise I barely register anymore.

  The cattle, feeling the end of their ride, start making a fuss, blending into the market's soundscape. Watching them being led away, I can't help but feel something's off without the older brother around, like there's an imbalance.

  The sun's relentless today, drenching me in sweat, making the tempo feel like an oven. On days like this, I find myself craving something cold, something to take the edge off. My eyes catch the sign of a general store near the vegetable market, a place I hardly ever visit, but now it seems inviting.

  Walking through Shivaji Market feels different, choosing to zigzag through the bustle instead of the shortcut past Farooq’s. The market's alive, each vendor's call, each negotiation, adding to its rhythm, a maze of colors and sounds.

  Approaching the general store, I notice an older guy heading there too. His slow pace makes me pause and watch. It's odd, the canal running by the store, a dark contrast to the bright vegetables nearby. Coming from a farm, I'm used to the cycle of life and death, but seeing the canal so close to where food is sold strikes me as strange.

  The store owner spots me and offers a reassuring smile, "Just animal blood," he says, trying to put me at ease without even knowing me. His smile and the familiar tone don't quite mask the weirdness of the canal being there.

  I nod, taking in the mixed smells of iron and earth, reminders of what this place is really about—a mix of life and making a living. The contrast between the market's energy and the quiet flow of the canal sparks a curiosity in me, a feeling that today's not like any other day, that there's something more to find out here.

  Standing by the store, lost in thought as my ice cream melts unnoticed, I see the store owner head back into the market, chatting with a woman at her stall. The sight of a lone bone by the canal, so near the fresh produce, only adds to my confusion.

  Hanging around outside the butchery, the butchers are on a smoke break, each with their own vibe. Aslam's laugh fills the air, Irfan gives off a calm vibe, and Junaid's all gestures and quick talks. I walk up, casually throwing out a question about what happens to the bones.

  Aslam, taking a drag from his cigarette, exhales a cloud of smoke before answering. "Oh, they're all accounted for," he says with a grin. "Nothing goes to waste here. The larger ones, well, they can be boiled down for stock, or some folks like them for their dogs."

  Irfan chimes in, his voice soft but firm, "And the smaller bits, we usually leave out for the strays. Can't have them going hungry, can we?" His smile reaches his eyes, a glint of kindness there that belies the rough exterior.

  Junaid, leaning against the wall, adds, "Yeah, nothing's left to drain. We make sure of that. It’s all part of the process, keeps everything in balance."

  Listening to them, everything seems to add up, but that bone by the canal tells a different story. I keep my discovery to myself, the mystery around the bone and the canal pulling me in deeper, making me think it's time to dig a little deeper.

  As the butchers finish up their break and get back to work, my curiosity turns into a full-blown need to investigate, to uncover the secrets hidden within the market.
        `
      },
      {
        title:"",
        text:""
      },
      {
        title:"",
        text:""
      }
    ],
    poetic:
    [
      {
      //riddles of the crimson flow 1st person (3)
      title: "Riddles of the Crimson Flow",
      text:`
      The steering wheel binds me, its chill and firmness unforgiving against my palms. It tethers me to an existence that loops perpetually through the cacophony of traffic and endless honks. Within the narrow confines of my tempo, the world compresses into a tiny box, its nooks crammed with grain sacks and permeated with the earthy scent of cattle. This mobile cell casts me as both its captive and keeper.

As I weave the tempo through the lifeblood of Pune, the radio springs to life, its narratives of distant realms and elusive love as far removed from my reality as the celestial bodies. The dashboard, trembling with each thud of bass, crafts an anthem of liberation, a fantasy where my spirit roams free. The cabin, adorned with peeling paint and a dimming charm swaying from the rearview mirror, encloses my solitude. Here, I'm isolated, yet paradoxically, I'm at the epicenter, navigating the disarray.

Each halt at a traffic light gifts me a momentary respite, a fleeting chance to venture beyond the confines of glass. Yet, as Shivaji Market draws near, those ephemeral dreams scatter. The market unfurls, a rich mosaic of color and din, reeling me back from the edge of my daydreams. I guide the tempo into the butcher's square, its ancient engine's growl preempting my arrival. Here, the melody from my radio is overpowered by the market's own symphony, a gentle reminder that within my tempo's bounds, I find my sanctuary from the chaos beyond.

The sons of Farooq-chacha, their frames lithe and movements sprightly, emerge from the shadows to greet me, their vivacity a stark contrast to the heaviness that cloaks my shoulders. They encircle the tempo's rear, their youthful energy making light of the arduous chore, their playful banter now a distant melody to me.

The cattle, sensing their journey's conclusion, stir and call out, their voices merging with the rhythmic pulse of the market. I stand vigilant, a mute guardian, as the beasts disembark. The absence of the older brother's steadying presence leaves a void, rendering me as unbalanced as a missing tooth.

The sun, in its relentless surveillance, showers its gaze upon me, tracing a rivulet of sweat along my spine, seeking the path of least resistance. On such days, when the cabin morphs into a furnace, I long for solace—a touch of coolness, a whisper of sweetness. This desire crystallizes with every bead of perspiration that escapes. My gaze lands on the faded sign of a general store at the fringe of the vegetable market, a rarely frequented haven now beckoning me with the promise of respite.

I navigate through the pulsating heart of Shivaji Market, opting to meander through its core rather than taking the straightforward path past Farooq’s butchery. The market vibrates with vitality, each vendor's cry and bargain a heartbeat threading through the cramped pathways adorned with a kaleidoscope of produce and merchandise.

Approaching the general store just outside the vegetable market, I'm captivated by the sight of an elderly man making his way to the seemingly deserted shop. His leisurely stride compels me to pause and watch, a silent observer once more. His movement is deliberate, as though he commands time to mold itself around him.

Adjacent to the shop, a discreet canal carves its path, a sharp contrast to the vibrant hues of the vegetables nearby. It's a dark artery, channeling away the somber remnants of Farooq's butchery. Having been raised on a farm, I'm well-acquainted with the cycle of livestock, yet the canal's proximity to the bounty of fresh produce strikes me as unusual.

The shopkeeper, upon noticing my towering figure at the doorway, welcomes me with a grin shaped by years of placating customers. "Merely animal blood," he offers, as though he can read my mind, oblivious to my identity. His persistent smile and the gentle crinkling of his eyes seek to soothe, yet they only serve to underscore the canal's curious placement.

I nod, the blend of metallic and earthy odors sharply reminding me of the market's true nature—a realm where life and sustenance are intricately entwined. The stark contrast between the market's vibrancy and the silent testament of the canal ignites an unforeseen curiosity within me. Today feels unlike any other; the market, as familiar to me as the lines on my palm, now conceals a mystery awaiting my discovery.

I linger by the shop, my attention riveted on the canal as the remnants of my ice cream succumb to neglect. The shopkeeper ventures back into the market's hustle, engaging with a stall owner as I ponder the canal's existence. The presence of a solitary bone near the canal, alarmingly close to the vegetables, deepens my bewilderment.

Outside the butchery, the butchers' casual chatter permeates the air as they enjoy a brief smoke break. They are a varied lot, each embodying the essence of their craft. Aslam's boisterous laughter resonates, Irfan's composed demeanor offers silent smiles, and Junaid's animated discussions rival the speed of his butchering skills. I stride towards them, my curiosity veiled beneath a guise of casual inquiry, and broach the subject of the bones' fate.

Aslam, in the midst of a puff, exhales a smoky veil before responding. "All is taken care of," he assures with a broad smile. "Nothing here is wasted. The larger bones find new purpose in broths or as treats for dogs."

Irfan, his voice a whisper of firmness, contributes, "The smaller fragments, we leave for the strays. It's important they're not left wanting."

Junaid, resting against the wall, adds his assurance, "Indeed, nothing is left to chance. We ensure a balance is maintained in all things."

Listening to their justifications, everything appears to align, yet the image of the bone lying so close to the produce contradicts their assurances. I withhold my observations, the enigma of the bone and the canal tightening its grasp on my thoughts, compelling me to probe deeper.

As the butchers snuff out their cigarettes and ready themselves to resume their duties, my curiosity unfurls into a quest for truth, driven to uncover the veiled secrets of the market.
      `
      },
      {
        title:"",
        text:""
      },
      {
        title:"",
        text:""
      }
    ],
  },
  long: {
    formal: [
      {
        title:"Mystery of the Market: Day of Discovery",
        text:
        `
        The steering wheel binds me, its chill grasp a relentless reminder of the cyclical existence to which I'm consigned. This cycle, perpetuated by endless traffic signals and the incessant cacophony of horns, ensnares me within the confines of my tempo. Within this narrow enclosure, the universe constricts to the dimensions of a mere matchbox, its every crevice overstuffed with grain sacks and permeated by the enduring aroma of cattle feed. This vehicle, my constant companion, transforms into a paradoxical cell on wheels, casting me in the dual roles of captive and custodian.

As I steer the tempo along the thoroughfares of Pune, the radio springs to life, weaving narratives of lands afar and tales of love that hang suspended just beyond my reach, as if amongst the stars themselves. The reverberations of the dashboard, responding to the bass's cadence, orchestrate a symphony of liberation—a reverie in which I'm a mere spectator. The cabin, adorned with its flaking coat of paint and the dim silhouette of a talisman swinging gently from the rearview mirror, becomes my solace of solitude. Here, I am simultaneously detached from the world and yet, paradoxically, situated right at its pulsating heart, navigating the tumult that unfolds around me.

Each pause at a red light emerges as an interlude in the narrative of my day, affording me brief escapades beyond the confines of glass. Yet, as the silhouette of Shivaji Market begins to materialize, those fleeting dreams dissipate into the ether. The market, with its vibrant tapestry of colors and symphony of sounds, reels me back from the precipice of my daydreams. I guide the tempo into the butcher's enclave, its venerable engine's drone preempting my presence before my feet grace the earth. In this moment, the melody that once filled my tempo yields to the intrinsic rhythm of the market, serving as a poignant reminder that within the limits of this mobile sanctuary, I find reprieve from the external chaos.

The progeny of Farooq-chacha, with their slender silhouettes radiating vibrancy and nimbleness, emerge from the sanctuary of the shadows to welcome me, their fervor casting a stark contrast to the mantle of weariness that I bear. They converge upon the tempo's rear, their youthful exuberance rendering the arduous task at hand seemingly effortless, their banter now a distant echo that fails to penetrate the veil of my consciousness.

The livestock, sensing the conclusion of their journey, become restless, their vocalizations melding seamlessly with the rhythmic heartbeat of the market. I assume the role of a silent sentinel, overseeing their departure from my vehicle. The palpable absence of the elder brother introduces an imbalance, rendering me as unsettled as a dislodged tooth.

The sun, unyielding in its scrutiny, casts its relentless gaze upon me, charting the course of perspiration that meanders down my spine, seeking the path of least resistance. On such days, when the confines of my cabin metamorphose into an inferno, I find myself yearning for solace—a touch of coolness, a sliver of sweetness. This yearning crystallizes with each droplet of sweat that escapes my brow. My gaze alights upon the time-worn sign of a general store nestled at the periphery of the vegetable market, a haven seldom sought yet now beckoning me with the allure of respite.

I meander through the animated chaos of Shivaji Market, opting to traverse its vibrant core rather than adopting the direct route through Farooq's domain. The market thrives with vitality, each vendor's proclamation and negotiation pulsating through the cramped corridors adorned with an array of produce and merchandise.

As I draw closer to the general store situated beyond the vegetable market's bounds, my attention is ensnared by the approach of an elderly gentleman. His measured pace invokes a pause within me, compelling me to observe in silence. His every step, deliberate and unhurried, as though orchestrating time to conform to his tempo.

Adjacent to the store, a modest canal traces its path, its existence a stark anomaly amidst the resplendent hues of the adjacent vegetable offerings. This shadowed conduit, tasked with ferrying away the somber remnants of Farooq's butchery, stands in stark contrast to the verdant bounty nearby. My upbringing on a farm has acquainted me with the cyclic nature of livestock, yet the canal's proximity to the produce imbues me with a sense of peculiarity.

The proprietor of the store, upon noticing my presence at the threshold, extends a greeting imbued with years of soothing customer concerns. "Merely animal blood," he proffers, as though deciphering my thoughts, yet oblivious to my identity. His persistent smile and the gentle crinkle of his eyes endeavor to offer solace, yet serve only to underscore the odd placement of the canal.

I acknowledge him with a nod, the amalgamation of scents—metallic and earthy—serving as a vivid testament to the market's essence, a realm where existence and sustenance are intricately interwoven. The stark dichotomy between the market's effervescence and the silent testimony of the canal's contents kindles within me an unforeseen curiosity. This day diverges from the mundane; the market, as familiar to me as my own skin, now conceals a mystery beckoning to be unveiled.

I linger by the shop's entrance, my focus affixed to the canal as the remnants of my ice cream succumb to neglect. The shopkeeper ventures forth once more into the market's din, engaging with a proprietor of a nearby stall as I ponder the existence of the canal. The discovery of a solitary bone resting near the canal, alarmingly proximate to the vegetables, compounds my bewilderment.

Stationed outside the butchery, the butchers' casual discourse permeates the air as they indulge in a brief respite. This eclectic assembly, each individual mirroring the essence of their craft, resonates with life. Aslam's boisterous laughter reverberates, Irfan's demeanor radiates tranquility, and Junaid's animated discourse mirrors the agility of his hands at work. Approaching them with a guise of casual inquiry, I broach the subject of the bones' destiny.

Aslam, amidst a cloud of smoke, reassures me, "All is in order," his visage breaking into a smile. "Here, nothing is squandered. The larger remnants find new purpose, be it in broths or as canine delicacies."

Irfan interjects, his tone both gentle and assertive, "The fragments, we allocate for the strays. It's our duty to ensure they're not left wanting."

Junaid, propped against the wall, corroborates, "Indeed, we leave no trace. Our practices maintain the equilibrium, ensuring harmony within our operations."

Absorbing their explanations, everything ostensibly aligns, yet the vision of the bone lying in close proximity to the vegetable market casts a shadow of doubt over their assurances. I withhold my observations, the enigma of the bone and the canal ensnaring my thoughts, compelling me to delve deeper into the undercurrents of the market's narrative.

As the butchers extinguish their cigarettes and ready themselves to recommence their labors, my intrigue unfurls into a quest for elucidation, emboldened to unravel the veiled intricacies of the market's secrets.
        `
      },
      {
        title:"",
        text:""
      },
      {
        title:"",
        text:""
      }
    ],
    informal: [
      {
        title:"",
        text:""
      },
      {
        title:"",
        text:""
      },
      {
        title:"",
        text:""
      }
    ],
    poetic: [
      {
        title:"",
        text:""
      },
      {
        title:"",
        text:""
      },
      {
        title:"",
        text:""
      }
    ],
  }
}


export default storyData